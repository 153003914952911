/*----------------------------------------------------------------------------------------------------------------------------------------------------
  Requests
 ----------------------------------------------------------------------------------------------------------------------------------------------------*/

.title-error{
    color: #e74c3c;
}

  /* Basic styles for the modal */
  .confirm-modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    overflow: scroll;
  }

  .confirm-box {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    max-width: 400px;
    width: 100%;
    margin: 15px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    transition: box-shadow 0.3s ease;    
  }

  .confirm-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
  }

  .confirm-buttons button {
    min-width: 100px;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    font-weight: 500;
    font-size: 0.9375rem;
    line-height: 1.75;
    color: rgb(255, 255, 255);
    text-transform: none;
    position: relative;
    box-shadow: none;
    padding: 8px 22px;
  }

  .confirm-buttons button.btn-cancel {
    color: rgb(2, 0, 52);
    padding: 8px 11px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1), border-color 250ms cubic-bezier(0.4, 0, 0.2, 1), color 250ms cubic-bezier(0.4, 0, 0.2, 1);
    background-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
}

  .confirm-buttons button.btn-cancel:focus, 
  .confirm-buttons button.btn-cancel:hover, 
  .confirm-buttons button.btn-cancel:active {
    background-color: lightgray !important;
    color: rgb(2, 0, 52) !important;
}



/* Slightly lighter red colour than normal bootstrap colour */
.alert-danger {
    background-color: #f5e5e5;
}

input.alert-danger {
    color: #555 !important;
}

.alert-danger::placeholder {
    color: #a94442 !important;
    opacity: 0.6;
}


/* MultiSelect */
.multiselect-options .option {
    padding: 6px 7px;
    border: 1px solid #ccc;
    border-radius: 20px;
    cursor: pointer;
    background-color: #f9f9f9;
    transition: background-color 0.3s;
    font-size: 14px;
}
.multiselect-options .selected {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
}

/* Continuum Slider */
.form-group input[type=range] {
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    margin-top: 6px;
    padding: 0 !important;
    box-shadow: none !important;
    webkit-box-shadow: none !important;
    border: none !important;
}

.form-group input[type=range]:hover {
    opacity: 1;
}

.form-group input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #007bff;
    cursor: pointer;
}

.form-group input[type=range]::-moz-range-thumb {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #007bff;
    cursor: pointer;
}

/*Form Wizard*/
.bs-wizard {
    margin-top: 0px;
}
.bs-wizard {
    border-bottom: solid 0px #e0e0e0;
    padding: 0 0 0 0;
}
.bs-wizard > .bs-wizard-step {
    padding: 0;
    position: relative;
}
.bs-wizard > .bs-wizard-step .bs-wizard-stepnum {
    color: #595959;
    font-size: 16px;
    margin-bottom: 5px;
}
.bs-wizard > .bs-wizard-step .bs-wizard-info {
    color: #999;
    font-size: 14px;
}
.bs-wizard > .bs-wizard-step > .bs-wizard-dot {
    position: absolute;
    width: 30px;
    height: 30px;
    display: block;
    background: #5a3ff5;
    top: 45px;
    left: 50%;
    margin-top: -15px;
    margin-left: -15px;
    border-radius: 50%;
}
.bs-wizard > .bs-wizard-step > .bs-wizard-dot:after {
    content: ' ';
    width: 14px;
    height: 14px;
    background: #233142;
    border-radius: 50px;
    position: absolute;
    top: 8px;
    left: 8px;
}
.bs-wizard > .bs-wizard-step > .progress {
    position: relative;
    border-radius: 0px;
    height: 8px;
    box-shadow: none;
    margin: 20px 0;
    background-color: #d4d4d6;
}
.bs-wizard > .bs-wizard-step > .progress > .progress-bar {
    width: 0px;
    box-shadow: none;
    background: #5a3ff5;
}
.bs-wizard > .bs-wizard-step.complete > .progress > .progress-bar {
    width: 100%;
}
.bs-wizard > .bs-wizard-step.active > .progress > .progress-bar {
    width: 50%;
}
.bs-wizard > .bs-wizard-step:first-child.active > .progress > .progress-bar {
    width: 0%;
}
.bs-wizard > .bs-wizard-step:last-child.active > .progress > .progress-bar {
    width: 100%;
}
.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot {
    background-color: #d4d4d6;
}
.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot:after {
    opacity: 0;
}
.bs-wizard > .bs-wizard-step:first-child > .progress {
    left: 50%;
    width: 50%;
}
.bs-wizard > .bs-wizard-step:last-child > .progress {
    width: 50%;
}
.bs-wizard > .bs-wizard-step.disabled a.bs-wizard-dot {
    pointer-events: none;
}
/*END Form Wizard*/

.email-header {
    margin: 10px 0 10px;
    font-size: 13pt;
    text-align: center;
}

.nomarginlr {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 15px;
}

.nomarginlr .col-xs-2 {
    width: 16.6666% !important;
}

.nomarginlr .col-xs-3 {
    width: 25% !important;
}

.nomarginlr .col-xs-4 {
    width: 33.3333% !important;
}

.nomarginlr .col-xs-5 {
    width: 41.6666% !important;
}

.nomarginlr .col-xs-6 {
    width: 50% !important;
}

.y-scroll {
    overflow-y: scroll;
}

.centerblock {
    margin: auto;
    display: block;
    background-image: none;
}

.formwell {
    background-color: #ffffff;
    padding: 15px;
    box-shadow: 0 16px 14px 0 rgba(12, 0, 51, 0.1);
    border-color: #ccc;
    border-width: 1px 1px 4px 1px;
}

#emailform_container {
    height: 100%;
    overflow: auto;
    background: #f5f5f5;
}

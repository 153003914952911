/*----------------------------------------------------------------------------------------------------------------------------------------------------
  Homepage
 ----------------------------------------------------------------------------------------------------------------------------------------------------*/

.logoroundedback {
    border-radius: 8px;
    background: transparent;
    padding: 1px;
    width: 160px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
}

@charset "UTF-8";

@keyframes example {
	from {background-color: #e74c3c;}
	to {background-color: yellow;}
}

.validation-message {
	animation-name: shake;
	animation-duration: 1s;
}

.validation-message::-webkit-input-placeholder {
	color: white;
}
/*----------------------------------------------------------------------------------------------------------------------------------------------------
  Youtube embed
 ----------------------------------------------------------------------------------------------------------------------------------------------------*/

@media only screen and (max-width: 475px) {
    .youtube-video {
        width: 265px;
        height: 148px;
    }
}

/*----------------------------------------------------------------------------------------------------------------------------------------------------
  Message
 ----------------------------------------------------------------------------------------------------------------------------------------------------*/

.message-text {
    /* color: white; */
    font-size: 1em;
}

.well {
    padding: 8px;
}

.top-buffer {
    margin-top: 20px;
}

.redtext {
    color: red;
}

#app {
    height: 100%;
}

#login_container {
    height: 100%;
    overflow: auto;
    background: #f5f5f5;
}

/*----------------------------------------------------------------------------------------------------------------------------------------------------
  Buttons
 ----------------------------------------------------------------------------------------------------------------------------------------------------*/

.center-div {
    margin: auto;
}

/*----------------------------------------------------------------------------------------------------------------------------------------------------
  Text
 ----------------------------------------------------------------------------------------------------------------------------------------------------*/

.ellipsis,
.ellipsis p {
    text-overflow: ellipsis;
    overflow: hidden;
}

.rn-animation-icon {
    background-size: contain;
    height: 40px;
    width: 40px;
    display: inline-block;
    /* margin-bottom: -3px; */
    margin-right: 5%;
    animation: spin 1.2s infinite alternate;
}

.center-div {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

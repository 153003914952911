.btn-primary {
    background-color: #5a3ff5;
    border-color: #5a3ff5;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active {
    background-color: #5a3ff5 !important;
    border-color: #5a3ff5;
}

.btn-secondary {
    background-color: #fff;
    border-color: #020034;
}
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary.active {
    background-color: #efeff0 !important;
    border-color: #020034;
}

#unauthorised_view {
    height: 100%;
}

.tooltip {
    height: 50px;
}

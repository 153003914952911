@import './3rd-party-styles/animate.css';
@import './styles/base.css';
@import './animations/base-animations.css';
@import './styles/common.css';
@import './styles/errorStyles.css';
@import './styles/homepage.css';
@import './styles/login.css';
@import './styles/requests.css';
@import './styles/emailform.css';
@import './styles/admin.css';
@import './styles/question-profile.css';
@import './styles/mobile-views.css';
@import './3rd-party-styles/vanillatoasts.css';

/* IMPORTANT DO NOT REMOVE 
- This is here for the purpose of ensuring the phone dropdown appears above other dropdowns
*/

.iti__country-list {
    z-index: 100;
}

.iti.iti--allow-dropdown {
    width: calc(100% - 58px);
}

/* IMPORTANT DO NOT REMOVE
- This is here to remove the box shadow / underline that appears in the bottom sheet component
*/

[data-rsbs-header] {
    box-shadow: none !important;
}

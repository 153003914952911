#vanillatoasts-container {
    position: fixed;
    width: 320px;
    font-weight: 400;
    pointer-events: none;
    z-index: 10000;
}
.toasts-top-right {
    top: 60px;
    right: 0;
}
.toasts-top-left {
    left: 0;
    top: 0;
}
.toasts-bottom-left {
    left: 0;
    bottom: 0;
}
.toasts-bottom-right {
    bottom: 0;
    right: 0;
}
.toasts-top-center {
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}
.toasts-bottom-center {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}
.vanillatoasts-toast {
    position: relative;
    padding: 16px 24px;
    margin: 20px;
    border-radius: 10px;
    cursor: pointer;
    animation-duration: 0.3s;
    animation-name: VanillaToasts;
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    pointer-events: all;
    box-shadow: 0 0 8px 1px #0000001c;
    background-color: #fff;
    color: #000;
}

.vanillatoasts-toast:after {
    border-right: 5px solid #de2301;
    content: '';
    padding-top: 53px;
    left: -2px;
    top: 20%;
    position: absolute;
    border-radius: 10px;
}

.vanillatoasts-fadeOut {
    animation-name: VanillaToastsFadeOut;
    animation-duration: 0.3s;
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-fill-mode: forwards;
}

#vanillatoasts-container p,
#vanillatoasts-container h4 {
    margin: 4px 0 !important;
}

.vanillatoasts-title {
    font-size: 15px;
    margin-bottom: 10px;
}

.vanillatoasts-text {
    font-size: 14px;
    color: #777;
    white-space: pre-wrap;
}

.vanillatoasts-icon {
    position: absolute;
    top: 5px;
    left: -40px;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    background: #fff;
}

.vanillatoasts-toast a,
.vanillatoasts-toast a:hover {
    color: #549edb !important;
    text-decoration: none !important;
}

/** toast types */
.vanillatoasts-success:after {
    border-right-color: #75fba8;
}

.vanillatoasts-warning:after {
    border-right-color: #ff934a;
}

.vanillatoasts-error:after {
    border-right-color: #de2301;
}

.vanillatoasts-info:after {
    border-right-color: #c0d6ef;
}

@keyframes VanillaToasts {
    from {
        transform: translate3d(400px, 0, 0);
        opacity: 0;
    }
    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes VanillaToastsFadeOut {
    from {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
    to {
        transform: translate3d(400px, 0, 0);
        opacity: 0;
    }
}

.user-headings,
.user-headings label,
.team-headings,
.team-headings label {
    padding-left: 1px;
}

.user-headings,
.team-headings {
    margin-bottom: 2%;
}

label {
    margin-left: 0;
}

.usr-enabled-label {
    text-align: center;
}

.editBtns {
    display: flex;
}

.editBtns .btn {
    padding: 8px 15px;
    background-color: #c0c0c0;
}

#editCancelBtn {
    margin-left: 4%;
}

.editable-user-row,
.add-user-row,
.editable-team-row,
.add-team-row {
    margin-bottom: 0;
    padding: 0;
    max-height: 45px;
}

.editable-user-row .form-group,
.add-user-row .form-group,
.editable-team-row .form-group,
.add-team-row .form-group {
    margin-bottom: 5px;
    margin-top: 5px;
}

.editable-item {
    padding-left: 0 !important;
    padding-right: 5px !important;
}

#add_user_name,
#add_team_name {
    margin-top: 0;
    padding-left: 0;
}

#add_user_btn,
#add_team_btn {
    margin-top: 5px;
}

.checkboxThree {
    width: 85px;
    height: 40px;
    background: #333;
    border-radius: 50px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.checkboxThree:before {
    content: 'On';
    position: absolute;
    top: 12px;
    left: 10px;
    height: 2px;
    color: #26ca28;
    font-size: 14px;
}

.checkboxThree:after {
    content: 'Off';
    position: absolute;
    top: 12px;
    left: 50px;
    height: 2px;
    color: white;
    font-size: 14px;
}

.checkboxThree label {
    display: block;
    width: 31px;
    height: 24px;
    border-radius: 50px;
    transition: all 0.5s ease;
    cursor: pointer;
    position: absolute;
    top: 9px;
    z-index: 1;
    left: 6px;
    background: #ddd;
}

.checkboxThree input[type='checkbox']:checked + label {
    left: 45px;
    background: #26ca28;
}

.roundedTwo input[type='checkbox'] {
    font-size: 12pt;
}

.editable-user-row .form-control,
.add-user-row .form-control {
    font-size: 12.5px;
    height: 40px;
    padding: 0px 7px;
}

.static-user-row > div,
.static-team-row > div {
    padding-left: 0;
}

#users-row .row .user-list-item:hover,
#teams-row .row .team-list-item:hover {
    background-color: #f2f2f2;
}

#users-row .row .user-list-item,
#teams-row .row .team-list-item {
    background-color: #fff;
    color: #2c3e50;
}

#users-row .row:not(:last-child) .user-list-item:not(:last-child),
#teams-row .row:not(:last-child) .team-list-item:not(:last-child) {
    border-bottom: 1px solid #ccc;
}

#users_list,
#teams_list {
    display: inline-block;
}

.getting-started {
    max-height: 50%;
}

.jumbotron h3 {
    font-size: 20px;
}
.jumbotron p {
    font-size: 18px;
}

.jumbotron {
    padding-left: 30px;
    padding-right: 30px;
}

@media only screen and (min-width: 768px) {
    .getting-started {
        max-height: 45%;
    }

    .jumbotron p {
        font-size: 18px;
    }

    .container .jumbotron,
    .container-fluid .jumbotron {
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media only screen and (max-width: 768px) {
    .getting-started {
        max-height: 50%;
    }
}

@media only screen and (max-width: 368px) {
    .getting-started {
        max-height: 45%;
    }
}

.static-text {
    padding: 3%;
    font-size: 15.5px;
    height: 40px;
    line-height: 32px;
    margin-bottom: 5px;
}

.static-text p {
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
    margin: 0;
}

.usr-enabled-input {
    display: flex;
}

.add-user-btns,
.add-team-btns {
    display: flex;
}

#close_user_btn,
#close_team_btn {
    margin-left: 4%;
    top: 0;
    margin-top: 5px;
    width: 45px;
}

#add_user_btns_div {
    margin-top: 0%;
}

.add-team-row {
    display: block;
}

.roundedTwo {
    margin: auto;
    margin-top: 12px;
}

.usr-enabled-input.col-sm-1 .roundedTwo {
    width: 48px;
    height: 28px;
    position: relative;
    margin: 30% 50%;
}

.usr-enabled-input .static-text {
    margin: auto;
}

#users_list,
#teams_list {
    overflow: auto;
    max-height: 80%;
    width: 100%;
}

#users-row,
#teams-row {
    height: 80%;
}

.admin-heading {
    font-weight: 500;
    font-size: 1.3em;
}

.admin-heading i:before {
    font-size: 0.8em;
}

@media only screen and (max-height: 650px) {
    #users-row,
    #teams-row {
        height: 70%;
    }
}

@media only screen and (max-height: 530px) {
    #users-row,
    #teams-row {
        height: 60%;
    }
}

@media only screen and (max-height: 450px) {
    #users-row,
    #teams-row {
        height: 50%;
    }
}

.editBtn {
    visibility: hidden;
}

@media only screen and (max-device-width: 1024px) {
    .editBtn {
        visibility: visible;
    }
}

@media only screen and (max-width: 767px) {
    #users_list,
    #teams_list {
        max-height: 90%;
    }

    .roundedTwo {
        width: 48px;
        height: 28px;
        position: relative;
        margin: 8% auto;
    }

    .usr-enabled-input.col-sm-1 .roundedTwo {
        width: 48px;
        height: 28px;
        position: relative;
        margin: 8% auto;
    }

    .static-text-value {
        margin-top: -9% !important;
    }

    .static-text {
        line-height: 42px;
    }

    .static-user-row {
        margin-bottom: 5%;
    }

    .editable-user-row .form-group,
    .add-user-row .form-group,
    .editable-team-row .form-group,
    .add-team-row .form-group {
        margin-bottom: unset;
        margin-top: unset;
    }

    .editable-user-row,
    .add-user-row,
    .editable-team-row,
    .add-team-row {
        max-height: unset;
    }

    .usr-mobile-enabled {
        margin-top: 3%;
    }

    .roundedTwo {
        margin: 0;
        margin-left: 12px;
        margin-top: -7px;
    }

    .editBtns,
    .add-user-btns,
    .add-team-btns {
        display: block;
    }

    #editCancelBtn {
        margin-left: 0;
        margin-top: 3%;
    }

    .editable-user-row {
        margin-top: 5%;
        margin-bottom: 3%;
    }

    .editable-team-row,
    .add-team-row {
        margin-bottom: 6%;
        margin-top: 2%;
    }

    .editable-user-row .col-xs-6 {
        padding-left: 0;
    }

    .user-list-buttons {
        margin-top: 7%;
    }

    .editable-text-label {
        margin-bottom: 0;
    }

    .editable-user-row .rolename .form-group,
    .add-user-row .rolename .form-group {
        width: 100%;
    }

    #close_user_btn,
    #close_team_btn {
        margin-left: 0;
    }

    #add_user_btns_div {
        margin-top: 3%;
    }

    .add-team-row {
        display: flex;
    }

    #close_team_btn {
        margin-top: 1px;
    }

    #add_team_btn {
        margin-top: 0;
    }

    .error-warning-icon {
        margin-top: 20px;
    }

    .editable-user-row .error-warning-icon {
        margin-top: 8px;
    }

    .editBtns,
    .add-team-btns {
        display: inline-block;
        margin-top: 5px;
    }

    .usr-enabled-input {
        display: block;
    }

    .mobile-admin-error {
        margin-top: 3%;
        padding-left: 0;
        padding-right: 0;
    }

    .add-user-row {
        margin-bottom: 5px;
        margin-top: 5px;
    }

    .jumbotron p {
        font-size: 15px;
    }
}

@media only screen and (max-width: 475px) {
    .user-list-buttons {
        margin-top: 9%;
    }

    .static-text {
        line-height: 32px;
    }

    .roundedTwo {
        margin: 0;
        margin-left: 12px;
        margin-top: -7px;
    }

    #add_user_btns_div {
        margin-top: 4%;
    }

    .usr-enabled-input {
        display: block;
    }

    .editBtns,
    .add-team-btns {
        display: inline-block;
        margin-top: -5px;
    }
}

.static-enabled,
.static-disabled {
    padding: 3px 10px;
    width: 75px;
    float: none;
    margin-top: 0;
    max-width: 68px;
}

.static-enabled {
    background-color: rgba(6, 173, 6, 0.75);
}

.static-disabled {
    background-color: rgba(251, 25, 0, 0.91);
}

#users_list,
#teams_list {
    list-style: none;
    padding: 0;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.static-email,
#userEmailInput {
    font-size: 0.7em;
    overflow-x: auto !important;
    text-overflow: clip !important;
}

.static-email::-webkit-scrollbar {
    background: transparent;
}

.saving-spinner .admin-spinner .la-ball-8bits {
    margin-top: 0;
}

.saving-spinner {
    display: inline-flex;
}

.saving-text {
    padding-left: 0;
}

.error-container-user {
    display: inline-block;
    height: 45px;
    margin-left: -2%;
}

.editable-user-row .form-control#userEmailInput {
    font-size: 10.5px;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
}

.team-list-item.rubberBand p {
    color: #eee;
}

.updateRowSuccess {
    -webkit-animation: background 2.5s cubic-bezier(1, 0, 0, 1);
    animation: background 2.5s cubic-bezier(1, 0, 0, 1);
}

@-webkit-keyframes background {
    from {
        background-color: #12b636;
        color: #fff;
        border-radius: 5px;
    }
    to {
        background-color: #fff;
        color: #2c3e50;
        border-radius: 0;
    }
}

@keyframes background {
    from {
        background-color: #12b636;
        color: #fff;
        border-radius: 5px;
    }
    to {
        background-color: #fff;
        color: #2c3e50;
        border-radius: 0;
    }
}

.user-list-row:hover > .user-list-buttons > .editBtn,
.team-list-row:hover > .team-list-buttons > .editBtn {
    visibility: visible;
}

.users-container,
.teams-container {
    margin-right: 0;
    height: 100%;
    margin-left: 5%;
}

.users-container,
.teams-container {
    position: fixed;
}

#admin_container {
    height: calc(100vh - 60px);
    display: flex;
    position: fixed;
    overflow: auto;
    width: 100%;
}

.error-warning-icon {
    height: 50px;
    position: relative;
}

.error-warning-icon i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.7em;
    color: red;
}

.list-spinner .admin-spinner {
    margin-left: auto;
    margin-right: auto;
}

#add_user_spinner,
#add_team_spinner {
    margin-left: 15%;
}

#add_user_btn,
#add_team_btn {
    width: 45px;
}

.credits-view,
.subscriptions-view,
.plans-view,
.cancel-view,
.credits-topup-view {
    position: relative;
    padding: 15px 15px 15px;
    margin-right: 0;
    margin-left: 0;
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 4px 4px 0 0;
    margin-bottom: 20px;
}

.credits-topup-view .thumbnail {
    padding: 10px;
}

.credits-view .title,
.credits-topup-view .title {
    font-size: 12px;
    font-weight: 700;
    color: #959595;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 10px;
    display: block;
}

.plans-step {
    position: relative;
    padding: 45px 15px 15px;
    margin-right: 0;
    margin-left: 0;
    background-color: #ffffff;
    border-radius: 4px 4px 0 0;
    margin-bottom: 20px;
}

.plans-step:after {
    position: absolute;
    top: 15px;
    left: 15px;
    font-size: 12px;
    font-weight: lighter;
    color: #959595;
    text-transform: uppercase;
    letter-spacing: 1px;
    content: 'Step 1 - Choose your credits';
}

.plans-step#step1:after {
    content: 'Step 1 - Choose your credits';
}

.plans-step#step2:after {
    content: 'Step 2 - Choose your plan';
}

.plans-step#step3:after {
    content: 'Step 3 - Total Cost';
}

.price-list {
    padding-left: 0;
    padding-right: 0;
}

.discount {
    color: green;
}

#plan_title {
    margin: 0;
}

.price-list.costs {
    font-weight: 500;
}

.info-message {
    width: 95%;
    padding-top: 1%;
    padding-bottom: 1%;
}

#editAcceptBtn,
#editCancelBtn,
#add_team_btn,
#close_team_btn {
    height: 45px;
    width: 45px;
}

#editUserBtn {
    height: 45px;
    width: 45px;
}

#credits-topup-input {
    margin-bottom: 10px;
}

@media only screen and (max-width: 768px) {
    .users-container,
    .teams-container {
        height: 95%;
    }

    #editTeamBtn {
        height: 45px;
        width: 45px;
    }

    .team-list-row {
        margin-top: 1%;
        margin-bottom: 1%;
    }
}

.admin-footer {
    display: block;
    height: 20px;
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: #243242;
}

/*----------------------------------------------------------------------------------------------------------------------------------------------------
  Login
 ----------------------------------------------------------------------------------------------------------------------------------------------------*/

 .panel-login {
	border-color: #ccc;
	border-width: 1px 1px 4px 1px;
}

.panel-title button {
	width: 100%;
}

.panel-login > .panel-heading hr {
	margin-top: 10px;
    margin-bottom: 0px;
    clear: both;
    border: 0;
    height: 1px;
	background-image: -webkit-linear-gradient(left,rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.15),rgba(0, 0, 0, 0));
}

.terms {
    text-decoration: underline;
    color: #888;
}

#pdf-btn {
	margin-top: 5%;
}

.version-text {
    color: #455D7A;
    font-size: 10pt;
}

.panel-body .message-container {
	padding-left: 15px;
	padding-right: 15px;
}

.panel-login .panel-heading p {
	font-size: 1em;
	font-weight: 500;
}

.panel-login {
	box-shadow: 0 16px 14px 0 rgba(12, 0, 51, 0.1);
}